import * as React from "react"
import Layout from "../../components/layout"
import logo from "../../images/navbar-logo.svg"

const SuccessPage = () => {
    return (
        <Layout>
        <div className="success-container">
            <img src={logo}  alt="logo"/>
            <h1 style={{marginTop: '2em'}}>Thank you for your inquiry. We will be in touch as soon as possible.</h1>
        </div>
        </Layout>
    )
}

export default SuccessPage